import React from "react";
import { Link } from "react-router-dom";

function Portfolio() {
  // doing stuff later
  /*
  Todo: 
  Make two main divs:
    1) Web-Dev
    2) Photography
    Optional // a third one for apps

  Use multiple div boxes to categorize your Pictures
  
  
  */

  return (
    //more stuff later
    <div>
      <section class="portfolio">
        <div>
          <p>Test</p>
        </div>
      </section>
    </div>
  );
}

export default Portfolio;
