import React from "react";
import { Link } from "react-router-dom";

function web3() {
  return (
    <section className="web03">
      <div className="container web03-main">
        <div>
          <h1>What is Web 3?</h1>
        </div>
        <br />
        <div>
          <br />
          <h2>Why Web 3.0?</h2>
          <br />
          <p>
            Even prior to Edward Snowden's revelations, we had realised that
            entrusting our information to arbitrary entities on the internet was
            fraught with danger. However, post-Snowden we can plainly see that
            large organisations and governments routinely attempt to stretch and
            overstep their authority. Thus we realise that entrusting our
            information to organisations in general is a fundamentally broken
            model. The chance of an organisation not meddling with our data is
            merely the effort required minus their expected gains. Given they
            tend to have an income model that requires they know as much about
            people as possible the realist will realise that the potential for
            convert misuse is difficult to overestimate. <br /> <br />
            The protocols and technologies on the Web, and even at large the
            Internet, served as a great technology preview. They each helped
            contribute to the sort of rich cloud-based applications we see today
            such as Google's Drive, Facebook and Twitter, not to mention the
            countless other applications ranging through games, shopping,
            banking and dating. However, going into the future, much of the core
            technology will have to be re-engineered according to our new
            understandings of the interaction between society and technology.{" "}
            <br />
            <br />
            Web 3.0, or as might be termed the "post-Snowden" web, is a
            reimagination of the sorts of things that we already use the Web
            for, but with a fundamentally different model for the interactions
            between parties. Information that we assume to be public, we
            publish. Information that we assume to be agreed, we place on a
            consensus-ledger. Information that we assume to be private, we keep
            secret and never reveal. Communication always takes place over
            encrypted channels and only with pseudonymous identities as
            endpoints; never with anything traceable (such as IP addresses). In
            short, we engineer the system to mathematically enforce our prior
            assumptions, since no government or organisation can reasonably be
            trusted. <br /> <br />
            There are four components to the post-Snowden Web: static content
            publication, dynamic messages, trustless transactions and an
            integrated user-interface. These are each designed to replace some
            aspect of the Web experience we currently take for granted, but to
            do so in a fully decentralised and pseudonymous manner. <br />{" "}
            <br />
            The first, we already have much of: a decentralised, encrypted
            information publication system. Two systems that already
            implementation much of what is necessary exist: Freenet and
            BitTorrent. This part of the system allows an individual to publish
            the parts of their website (or ĐApp, to use the new terminology)
            that are large and/or unchanging. Images, page templates, large
            portions of text and program code would fall under this umbrella.
            This information would take an address, with which any other
            individual would be able to download it. <br /> <br />
            Within Web 3.0, we are able, just as with Bit Torrent, to
            incentivise others to maintain and share this information, however
            we can make this more efficient and precise. Because an
            incentivisation framework is intrinsic to the protocol, we become
            (at this level, anyway) DDoS-proof by design. How's that for a
            bonus? <br /> <br />
          </p>
          <h2>Using Web 3.0 for messaging</h2>
          <br />
          <p>
            The second portion of Web 3.0 is a messaging and publication system
            for transient information. This is used for communicating, either
            privately between two individuals, or from one individual to all
            others, "broadcasting". <br />
            <br />
            As a communication system, it is, in principle, similar to the
            Internet itself. However, it has two key differences; firstly that
            the messages, if private, are always encrypted; thus no eavesdropper
            can determine what is being said between individuals. Secondly, the
            physical location of the end-points of the messages is cleverly
            hidden, and thus an eavesdropper cannot even collect the infamous
            "metadata": information that security services consider at once so
            useless to not be considered 'private', but yet so useful to spend
            billions in infrastructure to collect. <br /> <br />
            In Web 3.0 this portion allows individuals to communicate both
            securely nd privately, to give each other updates and to publish
            information in real-time, where the precedence of the messages needs
            not be intrinsically trusted or later referred: you cannot trust any
            of the messages to be "true", only that they came from the identity
            that they purport to have come from. <br /> <br />
          </p>
          <h2>Third Portion of Web 3.0</h2>
          <br />
          <p>
            The third portion of Web 3.0 is the consensus engine. A consensus
            engine is a means of agreeing some rules of interaction, in the
            knowledge that future interactions (or lack thereof) will
            automatically and irrevocably result in the enforcement exactly as
            specified. It is effectively an all-encompassing social-contract and
            draws its strength from the network effect of consensus. <br />{" "}
            <br />
            The fact that the effects of a renege of one agreement may be felt
            in all others is key to creating a strong social contract and thus
            making reducing the changes of renege or wilful ignorance. For
            example, the more a reputation system is isolated from a more
            personal social interaction system, the less effective the
            reputation system will be. A reputation system combined with
            Facebook or Twitter like functionality would work better than one
            without, since users place an intrinsic value on what their friends,
            partners or colleagues think of them. A particularly poignant
            example of this is the difficult question of whether, and when, to
            befriend on Facebook an employer or dating partner. <br /> <br />
            Consensus engines will be used for all trustful publication and
            alteration of information. This will happen through a completely
            generalised global transaction processing system the first workable
            example of which is the Ethereum project. <br /> <br />
            <strong>
              The traditional web does not fundamentally address consensus,
              instead falling back on centralised trust of authorities, such as
              ICANN, Verisign and Facebook, and reducing to private and
              government websites together with the software upon which they are
              built.{" "}
            </strong>
            <br /> <br />
          </p>
          <h2>Fourth portion of Web 3.0</h2>
          <br />
          <p>
            The fourth and final component to the Web 3.0 experience is the
            technology that brings this all together; the 'browser' and user
            interface. Funnily enough, this will look fairly similar to the
            browser interface we already know and love. There will be the URI
            bar, the back button and of course, the lions share will be given
            over to the display of the ĐApp (nÃ© webpage or website). <br />{" "}
            <br />
            There will be a few superficial differences; we'll see a move away
            from the traditional client-server URL model of addresses like
            "https://address/path", and instead start to see new-form addresses
            such as "goldcoin" and "uk.gov". Name resolution will be carried out
            by a consensus-engine-based contract and can trivially be redirected
            or augmented by the user. Periods would allow multiple levels of
            name resolution - "uk.gov", for example, might pass the "gov"
            subname into the name resolver given by "uk". <br /> <br />
            Due to the ever-transient nature of the information made available
            to the browser automatically and accidentally through the update of
            the consensus back-end and the maintenance of the peer network,
            we'll see background-ĐApps or ĐApplets play a great role in our Web
            3.0 experience. Either through always-visible Mac OS dock-like
            dynamic iconic infographics or dashboard style dynamic ĐApplets,
            we'll be kept accidentally up to date about that which we care.{" "}
            <br /> <br />
            After the initial synchronisation process, page-loading times will
            reduce to zero as the static data is pre-downloaded and guaranteed
            up to date and the dynamic data (delivered through the
            consensus-engine or p2p-messaging-engine) are also maintained up to
            date. While being synchronised, the user-experience will be
            perfectly solid though actual information shown may be out of date
            (though may easily not, and can be annotated as such). <br /> <br />
            As a user of Web 3.0, all interactions will be carried out
            pseudonymously, securely and for many services, trustlessly. Those
            that require third party(-ies), the tools will give the users and
            ĐApp-developers the ability to spread the trust among multiple
            different, possibly competing, entities, massively reducing the
            amount of trust one must place in the hands of any given single
            entity. <br /> <br />
            The changeover will be gradual, on Web 2.0, we'll increasingly see
            sites whose back-ends utilise Web 3.0-like components such as
            Bitcoin, BitTorrent, NameCoin. This trend will continue and the
            truly Web-3.0 platform Ethereum will likely be used by sites that
            wish to provide transactional evidence of their content e.g. voting
            sites and exchanges. Of course, a system is only as secure as the
            weakest link and so eventually such sites will transition themselves
            onto a Web 3.0 browser which can provide end-to-end security and
            trustless interaction. <br /> <br />
            Say 'hello' to Web 3.0, a Secure Social Operating System.
          </p>
        </div>
        <br />
        <div>
          <p>
            Here's the Original Link to the Artikel:{" "}
            <a href="http://gavwood.com/web3lt.html">
              http://gavwood.com/web3lt.html
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default web3;
