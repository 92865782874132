import React from "react";
import { Link } from "react-router-dom";

function Home() {
  function sendMail() {
    window.location = "mailto:enrikokorezki@gmail.com";
  }

  return (
    // Home stuff
    <div>
      <section class="hero">
        <div class="heroText">
          <h1 class="heroh1">Enriko Korezki</h1>
          <h3 class="heroh3">Developer. Student. Creating since 2019.</h3>
        </div>
        <div class="heroCTA">
          <Link class="" to="/contact">
            <button class="heroButton ">
              <span class="heroButtonText">Contact</span>
            </button>
          </Link>
          <Link class="" to="/portfolio">
            <button class="heroButton">
              <span class="heroButtonText">Portfolio</span>
            </button>
          </Link>
          <div class="pf-header">
            <h1>My Portfolio</h1>
          </div>
        </div>
      </section>
      <div class="diagonal-section">
        <section className="portfolio">
          <div class="content">
            <div class="d-flex justify-content-center ">
              <div class="row ">
                <Link
                  to="/portfolio/web-development"
                  class="hvr-float"
                  style={{ textDecoration: "none" }}
                >
                  <div class="pf-web-box content-box">
                    <h1 class="content-header">Web Development</h1>
                  </div>
                </Link>

                <Link
                  to="/portfolio/photography"
                  class="hvr-float"
                  style={{ textDecoration: "none" }}
                >
                  <div class="pf-photo-box content-box">
                    <h1 class="content-header">Photography</h1>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="diagonal-section">
        <section class="about-me">
          <div class="content">
            <div class="ab-header">
              <h1>About Me</h1>
            </div>
            <div class="ab-content d-flex justify-content-center ">
              <div class="row ab-fix">
                <div class="ab-img col-4"></div>
                <div class="ab-text col-8">
                  <p>
                    Imagine some inspirational story. <br />
                    I am just an 18 Year old lad who is pationate on creating
                    new things. <br />
                    <br />
                    This page is currently in a Demo state, therefore you can't
                    <br />
                    access all the pages or use all the functions on this
                    website.
                  </p>
                  <br />
                  <Link to="/about">
                    <button class="abButton">Click me</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="contact-us">
        <div class="cu-header">
          <h1>Contact me</h1>
          <p class="">Regardless of the Topic, feel free to get in touch ))</p>
        </div>
        <div class="cu-content" href="mailto:enrikokorezki@gmail.com">
          <a
            href="mailto:enrikokorezki@gmail.com"
            type="button"
            class="cuButton"
          >
            Email
          </a>
        </div>
      </section>
    </div>
  );
}

export default Home;
