import React from "react";
import { Link } from "react-router-dom";

function SocialMedia() {
  return (
    //Social media stuff
    <div>
      <section class="social-media">
        <div class="social-media-header">
          <h1>Social Media</h1>
        </div>
        <div class="d-flex justify-content-center content-boxes">
          <div class="row container">
            <Link
              to={{ pathname: "https://www.instagram.com/enrikokorezki/" }}
              target="_blank"
              class="col-sm hvr-float"
              style={{ textDecoration: "none" }}
            >
              <div class="social-insta-box content-box">
                <h1 class="content-header">Instagram</h1>
              </div>
            </Link>

            <Link
              to={{
                pathname:
                  "https://www.youtube.com/channel/UCK511cTMK14Uk-hArHdGgRQ/",
              }}
              target="_blank"
              class="col-sm hvr-float"
              style={{ textDecoration: "none" }}
            >
              <div class="social-yt-box content-box">
                <h1 class="content-header">Youtube</h1>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SocialMedia;
