import React, { useState, useEffect } from "react";
import "./App.css";
import "./components/css/phone.css";
import "./components/css/tablet.css";
import "./components/css/monitor.css";
import "./components/css/burger-menu.css";
import { ScaleLoader } from "react-spinners";
import swal from 'sweetalert';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Footer, Home, SocialMedia, About, Portfolio, Web3 } from "./components";

function App() {

  // Adding pre-loader
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      swal("Page under construction!", "This page is currently under development. Therefore, some features/pages are not fully functional or not existent!")
    }, 1000)
  }, []);

  return (
    <div className="App" >

      {
        loading ? (
          <div className="loaderstuff">
            <h1 className="heroh1">Enriko Korezki</h1>
            <h3 className="heroh3">Developer. Student. Creating since 2019.</h3>
            <ScaleLoader color={"white"} loading={loading} height={35} width={10} radius={20} margin={7} />
          </div>

        )

          : (


            < Router >
              <Navigation />
              <Switch>

                {/* Main pages */}
                <Route path="/" exact component={() => <Home />} />
                <Route path="/about" exact component={() => <About />} />
                <Route path="/portfolio" exact component={() => <Portfolio />} />

                {/*Random pages */}
                <Route path="/social-media" exact component={() => <SocialMedia />} />
                <Route path="/web3" exact component={() => <Web3 />} />
                {/*<Route component={NotFound} />*/}
              </Switch>
              <Footer />
            </Router>
          )
      }
    </div >
  );
}

export default App;
