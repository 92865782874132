import React from "react";

function About() {
  return (
    //Social media stuff
    <section id="testtestsection">
      <div>
        <p class="text-yellow-400">0</p>
      </div>
      <div class="testtestdiv">
        <p class="text-4xl text-yellow-400">About me</p>
        <button class="bg-green-200">a</button>
        {/**Cool shit for About us page: https://www.framer.com/motion/ */}
      </div>
    </section>
  );
}

export default About;
