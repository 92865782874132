import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { FaInstagram, FaYoutube } from "react-icons/fa";

// test
import { push as Menu } from "react-burger-menu";

let checkWidth = window.innerWidth;

function Navigation(props) {
  const [navbar, setNavbar] = useState(false);

  // adjusts background style on detected scroll
  const changeBackground = () => {
    //console.log(window.scrollY);
    if (window.scrollY >= 70) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  // End of Scroll

  // if screensize = phone
  if (checkWidth <= 849) {
    return (
      <Menu>
        <a id="home" className="menu-item" class="m-item" href="/">
          Home
        </a>
        <a id="about" className="menu-item" class="m-item" href="/about">
          About Me
        </a>
        <a
          id="portfolio"
          className="menu-item"
          class="m-item"
          href="/portfolio"
        >
          Portfolio
        </a>
        <a id="products" className="menu-item" class="m-item" href="/products">
          Products
        </a>
        <a id="contact" className="menu-item" class="m-item" href="/contact">
          Contact
        </a>

        <a id="contact" className="menu-item" href="/login">
          <button class="m-login-item">Login</button>
        </a>
        <div class="navbar-brand">
          <Link
            to={{ pathname: "https://www.instagram.com/enrikokorezki/" }}
            target="_blank"
            class="m-insta-item"
          >
            <FaInstagram class="navbar-icons" />
          </Link>
          <Link
            to={{
              pathname:
                "https://www.youtube.com/channel/UCK511cTMK14Uk-hArHdGgRQ",
            }}
            target="_blank"
            class="m-youtube-item"
          >
            <FaYoutube class="navbar-icons" />
          </Link>
        </div>
      </Menu>
    );
    //alert("phone");
  }

  // Monitor size
  else {
    return (
      <div className="navigation">
        <nav
          class="navbar navbar-expand navbar-dark fixed-top"
          className={
            navbar
              ? "navbar navbar-expand navbar-dark fixed-top nav-active navbar-brand-active nav-link-active "
              : "navbar navbar-expand navbar-dark fixed-top"
          }
        >
          <div class="container-fluid">
            <div class="navbar-brand">
              <Link
                to={{ pathname: "https://www.instagram.com/enrikokorezki/" }}
                target="_blank"
              >
                <FaInstagram class="navbar-icons" />
              </Link>
              <Link
                to={{
                  pathname:
                    "https://www.youtube.com/channel/UCK511cTMK14Uk-hArHdGgRQ",
                }}
                target="_blank"
              >
                <FaYoutube class="navbar-icons" />
              </Link>
            </div>

            <div>
              <ul class="navbar-nav justify-content-center">
                <li
                  class={`nav-item  ${
                    props.location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link class="nav-link" to="/">
                    Home
                    <span class="sr-only">(current)</span>
                  </Link>
                </li>
                <li
                  class={`nav-item  ${
                    props.location.pathname === "/about" ? "active" : ""
                  }`}
                >
                  <Link class="nav-link" to="/about">
                    About Me
                  </Link>
                </li>
                <li
                  class={`nav-item  ${
                    props.location.pathname === "/portfolio" ? "active" : ""
                  }`}
                >
                  <Link class="nav-link" to="/portfolio">
                    Portfolio
                  </Link>
                </li>
                <li
                  class={`nav-item  ${
                    props.location.pathname === "/products" ? "active" : ""
                  }`}
                >
                  <Link class="nav-link" to="/products">
                    Products
                  </Link>
                </li>
                <li
                  class={`nav-item  ${
                    props.location.pathname === "/contact" ? "active" : ""
                  }`}
                >
                  <Link class="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <Link class="navbar-brand" to="/login">
              Login
            </Link>
          </div>
        </nav>
      </div>
    );
  }
}
export default withRouter(Navigation);

// if mobile size
/**
 function MobileBg() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
      // maybe set a static value?
      setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
      function handleResize() {
        setWindowDimension(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    // setting static mobile width value
    const isMobile = windowDimension <= 849;

}
 */

//export default navigation
